<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="$router.go(-1)"
            size="36"
            style="cursor: pointer;"
            color="#e5e5e5"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#333" size="28" style="padding-bottom: 8px;"
            >mdi-currency-usd</v-icon
          >
          <span style="font-size: 19pt; text-wrap: nowrap;" class="fonte-bold">
            Solicitar Pagamento
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                1. Informe o cpf do pagador
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                placeholder="000.000.000-00"
                v-mask="['###.###.###-##']"
                prepend-inner-icon="mdi-account-circle-outline"
                large
                v-model="cpf"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal centraliza">
                <v-btn
                  @click="getDestUser"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Buscar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="2">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex xs12>
              <v-list class="fonte" color="transparent">
                <v-list-item class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Pagador
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-cash</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $helper.formataSaldo(balance.balance) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Saldo disponível
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list>
            </v-flex>
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                2. Valor a solicitar
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                prefix="R$"
                v-mask="[
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '##.###,##',
                  '###.###,##'
                ]"
                placeholder="23,50"
                large
                v-model="transfer_value"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal column centraliza">
                <v-btn
                  @click="createRequest"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Solicitar</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '1'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="3">
        <v-flex xs12 class="px-3" md6>
          <div
            class="expande-horizontal centraliza wrap"
            style="min-height: 50vh;"
          >
            <!-- <v-otp-input length="6"></v-otp-input> -->
            <div class="expande-horizontal centraliza column">
              <span class="fonte-bold" style="font-size: 26pt;">{{
                $helper.formataSaldo(transfer_value)
              }}</span>
              <span class="fonte-bold grey--text" style="font-size: 19pt;"
                >Solicite a senha ao cliente</span
              >
            </div>

            <div v-if="!loading" class="expande-horizontal">
              <v-text-field
                v-for="(digit, index) in otpLength"
                :key="index"
                v-model="otp[index]"
                filled
                :ref="`otpInput${index}`"
                class="ml-1"
                type="Number"
                style="display: flex; justify-content: center; min-height: 100px; padding-left: 5px;"
                maxlength="1"
                @input="focusNext(index)"
              ></v-text-field>
            </div>
            <v-btn
              :loading="loading"
              x-large
              @click="approve"
              color="black"
              class="white--text"
              depressed
              rounded
              >Aprovar</v-btn
            >
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="4">
        <v-flex xs12 class="px-3" md6>
          <div
            style="min-height: 50vh;"
            class="expande-horizontal centraliza column"
          >
            <v-avatar size="100" color="green">
              <v-icon color="white" size="80">mdi-check</v-icon>
            </v-avatar>
            <span class="fonte-bold mt-6" style="font-size: 21pt;"
              >Pagamento Aprovado</span
            >
            <v-btn
              color="black"
              class="my-6"
              dark
              depressed
              rounded
              x-large
              @click="step = '1'"
              >Novo Pagamento</v-btn
            >
            <v-btn color="black" text @click="$router.go(-1)">Sair</v-btn>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="5">
        <v-flex xs12 class="px-3" md6>
          <div
            style="min-height: 50vh;"
            class="expande-horizontal centraliza column"
          >
            <v-avatar size="100" color="red">
              <v-icon color="white" size="80">mdi-close</v-icon>
            </v-avatar>
            <span class="fonte-bold mt-6" style="font-size: 21pt;">{{
              error
            }}</span>
            <v-btn
              color="black"
              class="my-6"
              dark
              depressed
              rounded
              x-large
              @click="tryAgain"
              >Tentar Novamente</v-btn
            >
            <v-btn color="black" text @click="$router.go(-1)">Sair</v-btn>
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      user: {},
      balance: {},
      step: "1",
      cpf: "",
      error: "",
      transfer_value: "",
      loading: false,
      otp: ["", "", "", "", "", ""], // Array para armazenar os dígitos do OTP
      otpLength: 6, // Tamanho do código OTP
      transactions: [],
      unilevel: []
    };
  },
  methods: {
    ...mapActions([
      "getDestinationUser",
      "createRequestPromise",
      "approveRequestPromise"
    ]),
    getDestUser() {
      this.loading = true;
      this.getDestinationUser({
        cpf: this.cpf,
        transaction_type: this.transaction_type
      }).then(res => {
        this.loading = false;
        this.user = res.user;
        this.balance = res.balance;
        this.step = "2";
      });
    },
    createRequest() {
      if (parseFloat(this.transfer_value) < 1) {
        alert("O valor deve ser 1 real ou maior");
        return;
      }
      this.loading = true;
      this.createRequestPromise({
        value: this.transfer_value.replace(',', '.'),
        user: {
          _id: this.user._id
        }
      }).then(res => {
        this.step = "3";
        this.loading = false;
        this.transactions = res.promises;
        this.unilevel = res.unilevel;
        setTimeout(() => {
          this.$refs["otpInput0"][0].focus();
        });
      });
    },
    focusNext(index) {
      if (index === 5) {
        this.loading = true;
        this.approve();
      } else if (index < this.otpLength - 1 && !this.loading) {
        const nextInput = this.$refs[`otpInput${index + 1}`];
        nextInput[0].focus();
      }
    },
    tryAgain() {
      this.otp = ["", "", "", "", "", ""];
      setTimeout(() => {
        this.step = "3";
      }, 300);
    },
    approve() {
      this.loading = true;
      this.approveRequestPromise({
        code: this.code,
        transactions: this.transactions,
        unilevel: this.unilevel,
        user: {
          _id: this.user._id
        }
      })
        .then(() => {
          this.step = "4";
          this.loading = false;
          this.reset();
        })
        .catch(e => {
          this.error = e.response.data.message;
          this.loading = false;
          this.step = "5";
        });
    },
    reset() {
      this.otp = ["", "", "", "", "", ""];
      this.transactions = [];
      this.error = "";
      this.transfer_value = "";
      this.user = {};
      this.balance = {};
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    code() {
      let code = this.otp.join("");
      return code;
    }
  }
};
</script>
